
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import FormField from '@/components/forms/FormField.vue';
import { Actions as ReActions } from '@/store/enums/ResourcesEnums';

export default defineComponent({
  name: 'wizard-rental-property-details',
  props: {
    existingTenant: {
      type: Object,
    },
  },
  components: {
    FormField,
  },
  data: () => ({
    loading: false,
    isLoad: false,
  }),
  method: {
    ...mapActions({
      disabledDates: ReActions.DISABLE_DATES_TOMORROW,
    }),
  },
  // mounted(): {
  //   // if (!isEmpty(this.$router.currentRoute.value.query)) {
  //   //     const queryPayload = router.currentRoute.value.query;
  //   //     set(existingAccount, 'first_name', queryPayload?.first_name);
  //   //     set(existingAccount, 'last_name', queryPayload?.last_name);
  //   //     set(existingAccount, 'date_of_birth', queryPayload?.date_of_birth);
  //   //   }
  // },
  computed: {},

  setup() {
    // const store = useStore();
    // const showPassword = ref<boolean>(false);
    // const showConfirmPassword = ref<boolean>(false);
    // const router = useRouter();
    // const existingAccount = reactive({
    //   first_name: '',
    //   last_name: '',
    //   date_of_birth: null,
    // });
    // const email = ref('');
    // const mobile = ref('');
    // const hasExisting = ref<null | boolean>(null);
    // const isLoadState = ref<boolean>(false);
    // const disabledDate = (time: Date) => {
    //   return (
    //     time.getTime() > Date.now() || time.getDate() === new Date().getDate()
    //   );
    // };
    // onMounted(() => {
    // });
    // const getExistingAccount = debounce(async () => {
    //   isLoadState.value = true;
    //   await store
    //     .dispatch(Actions.SEARCH_TANANT_RECORDS, {
    //       ...existingAccount,
    //       ...{
    //         date_of_birth: moment(existingAccount.date_of_birth).format(
    //           'Y-MM-DD'
    //         ),
    //       },
    //     })
    //     .then(() => {
    //       email.value = get(store.getters, 'tenantRecord.email');
    //       mobile.value = get(store.getters, 'tenantRecord.mobile_number');
    //       hasExisting.value = true;
    //     })
    //     .catch(() => {
    //       hasExisting.value = false;
    //       email.value = '';
    //       mobile.value = '';
    //     })
    //     .finally(() => {
    //       isLoadState.value = false;
    //     });
    // }, 1000);
    // watch(existingAccount, async (val) => {
    //   const { first_name, last_name, date_of_birth } = val;
    //   if (!!first_name && !!last_name && !!date_of_birth) {
    //     getExistingAccount();
    //   }
    // });
    // return {
    //   email,
    //   mobile,
    //   isLoadState,
    //   hasExisting,
    //   disabledDate,
    //   showPassword,
    //   existingAccount,
    //   showConfirmPassword,
    // };
  },
});
