import regexUtil from '@/utils/regex';
import {
  createValidationObjectSchema,
  formatMatches,
} from '@/utils/validations';

export const Tenant = createValidationObjectSchema({
  email: {
    format: 'email',
    required: true,
  },
  first_name: {
    required: true,
  },
  last_name: {
    required: true,
  },
  date_of_birth: {
    required: true,
  },
  mobile_number: {
    required: true,
    match: [regexUtil.mobile.generic, 'Mobile number is not a valid number'],
  },
});

export const RentalProperty = createValidationObjectSchema({
  move_in_date: {
    required: true,
  },
  move_out_date: {
    required: true,
    min: ['move_in_date', "Move out date can't be before Move in date"],
  },
  rent_per_week: {
    required: true,
    match: [regexUtil.float],
  },
  address_line: {
    required: true,
  },
  state: {
    required: true,
  },
  city: {
    required: true,
  },
  country: {},
  post_code: {
    required: true,
    match: formatMatches.postalCode.aus,
  },
});
