
import * as bootstrap from 'bootstrap';
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { mapActions, mapGetters, useStore } from 'vuex';
import { StepperComponent } from '@/assets/ts/components';
import { each, toString, get, cloneDeep, find } from 'lodash';

import * as Yup from 'yup';
//import type { TenantQuestions } from '@/store/modules/TenantModule';

import { TenantReview } from '@/models/TenantReviewModel';

import TenantDetails from './steps/TenantDetails.vue';
import RentalPropertDetails from './steps/RentalPropertDetails.vue';
import RentalReviewForm from './steps/RentalReviewForm.vue';
import UploadTenantLedger from './steps/UploadTenantLedger.vue';

import { Tenant, RentalProperty } from '@/schemas/tenantReview.schema';

import { Getters as StoreGetters } from '@/store/enums/StoreEnums';
import {
  Getters as RQGetters,
  Actions as RQActions,
  Mutations as RQMutations,
} from '@/store/enums/ReviewQuestionsEnums';
import { Getters } from '@/store/enums/TenantReviewEnums';

const initialValues = {
  country: 'AU',
  timezone: '',
} as unknown as TenantReview;

export default defineComponent({
  name: 'submit-tenant-review-wizard',
  components: {
    Form,
    Step1: TenantDetails,
    // Step2: RentalPropertDetails,
    // Step3: RentalReviewForm,
    // Step4: UploadTenantLedger,
  },
  data: () => ({
    currentStep: 0,
    form: {} as typeof Form,
    formData: initialValues,
    loading: false,
    stepper: {} as StepperComponent,
    updateState: false,
  }),
  mounted() {
    //fetch review questions
    this.getReviewQuestions();

    // setup the form reference
    this.form = this.$refs.submitTenantReviewForm as typeof Form;

    // setup the stepper
    // this.stepper = StepperComponent.createInstance(
    //   this.$refs.tenantReviewStepperForm as HTMLElement
    // ) as StepperComponent;
  },

  computed: {
    ...mapGetters({
      errors: Getters.GET_TENANT_REVIEW_ACTION_ERROR,
      reviewQuestions: RQGetters.GET_REVIEW_QUESTIONS,
    }),

    formStepSchema() {
      return this.formSchema[this.currentStep];
    },

    formSchema() {
      return [Tenant, RentalProperty];
    },
  },
  methods: {
    ...mapActions({
      getReviewQuestions: RQActions.FETCH_REVIEW_QUESTION,
    }),
  },
  setup(props, { emit, expose }) {
    // const currentStepIndex = ref(0);

    // const questions = ref<Array<TenantQuestions>>([]);
    // const step3Ref = ref(null);
    // const store = useStore();

    // const currentSchema = computed(() => {
    //   return createAccountSchema[currentStepIndex.value];
    // });

    // const { resetForm, handleSubmit } = useForm<IStep1 | IStep2>({
    //   validationSchema: currentSchema,
    // });

    // const totalSteps = computed(() => {
    //   if (!_stepperObj.value) {
    //     return;
    //   }

    //   return _stepperObj.value.totatStepsNumber;
    // });

    // resetForm({
    //   values: {
    //     ...formData,
    //   },
    // });

    // const handleStep = handleSubmit((values) => {
    //   formData = {
    //     ...formData,
    //     ...values,
    //   };

    //   resetForm({
    //     values: {
    //       ...formData,
    //     },
    //   });

    //   currentStepIndex.value++;

    //   if (!_stepperObj.value) {
    //     return;
    //   }

    //   _stepperObj.value.goNext();
    // });

    // const previousStep = () => {
    //   if (!_stepperObj.value) {
    //     return;
    //   }

    //   currentStepIndex.value--;

    //   _stepperObj.value?.goPrev();
    // };

    // const formSubmit = (ledger_file) => {
    //   const QuestionFormPayload = get(step3Ref, 'value.formModel');
    //   const review_answers: Array<{
    //     tenant_question_id: string;
    //     answer: string;
    //     type: string;
    //   }> = [];

    //   if (typeof QuestionFormPayload === 'object') {
    //     each(QuestionFormPayload, (val: string | number, item_name) => {
    //       const type = get(
    //         find(questions.value, { id: item_name }),
    //         'type'
    //       ) as string;

    //       review_answers.push({
    //         tenant_question_id: item_name,
    //         answer: toString(val),
    //         type: type,
    //       });
    //     });
    //   }

    //   emit('submit', {
    //     ...formData,
    //     ...{ review_answers, ledger_file },
    //   });
    // };

    // const reset = () => {
    //   _stepperObj.value?.goFirst();
    //   currentStepIndex.value = 0;
    //   resetForm({ values: { ...new ReportFormModel().value } });
    // };

    // expose({ resetWizard: reset(), step3Ref });

    return {
      // horizontalWizardRef,
      // step3Ref,
      // previousStep,
      // handleStep,
      // formSubmit,
      // totalSteps,
      // currentStepIndex,
      // questions,
      // reset,
    };
  },
});
