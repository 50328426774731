
// import { dateSanitized, numberSanitized } from '@/core/utils/PayloadSanitized';
import { defineComponent } from 'vue';
import SubmitReportWizzard from '@/components/forms/tenant-reviews/SubmitReportWizardForm.vue';
// import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
// import { joinErrors } from '@/core/utils/ResponseErrorGetters';
// import { Actions } from '@/store/enums/TenantEnums';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
// import { ElMessage } from 'element-plus';
// import { useRouter } from 'vue-router';
// import { useStore } from 'vuex';
// import { cloneDeep, get, isArray, isEmpty } from 'lodash';
// import { useForm } from 'vee-validate';
// import * as Yup from 'yup';

export default defineComponent({
  name: 'submit-tenant-review-page',
  components: {
    UserForm: SubmitReportWizzard,
  },
  //setup() {
  // const store = useStore();
  // const isComplete = ref<boolean>(false);
  // const wizard = ref(null);
  // const emails = ref<Array<string>>([]);
  // const isSendingEmail = ref(false);
  // // Mthod Submit Request
  // const submit = async (payload) => {
  //   Swal.fire({
  //     text: 'Please Wait',
  //     allowOutsideClick: false,
  //     onBeforeOpen: () => {
  //       Swal.showLoading();
  //     },
  //   });
  //   // Sanitized the payload date format
  //   dateSanitized(payload, [
  //     'date_of_birth',
  //     'move_out_date',
  //     'move_in_date',
  //   ]);
  //   // Sanitized the payload number format removes spaces
  //   numberSanitized(payload, ['rent_per_week', 'mobile_number']);
  //   //   await store
  //   //     .dispatch(Actions.SUBMIT_TENANT_REFERENCE, payload)
  //   //     .then(() => {
  //   //       Swal.close();
  //   //       isComplete.value = true;
  //   //     })
  //   //     .catch(() => {
  //   //       const error = get(store, 'getters.getErrors', []);
  //   //       Swal.fire({
  //   //         text: isArray(error)
  //   //           ? joinErrors(get(store, 'getters.getErrors', []))
  //   //           : error,
  //   //         icon: 'error',
  //   //         buttonsStyling: false,
  //   //         confirmButtonText: 'Try again!',
  //   //         customClass: {
  //   //           confirmButton: 'btn btn-primary',
  //   //         },
  //   //       });
  //   //     });
  // };
  // onMounted(() => {
  //   setCurrentPageTitle('Submit Tenant Reference');
  // });
  // const link = computed((): string => {
  //   const id = get(
  //     store,
  //     'getters.tenantRecord.tenant_id',
  //     '70436b9e-b51d-4752-b7ed-27d7f21a814e'
  //   );
  //   const URL = process.env.VUE_APP_BASE_ROUTE;
  //   const PATH = useRouter().resolve({
  //     name: 'tenant-report-profile-view',
  //     params: { id },
  //   });
  //   return `${URL}${PATH.fullPath}`;
  // });
  // const copyLink = () => {
  //   navigator.clipboard.writeText(link.value);
  //   ElMessage({
  //     message: 'Link copied.',
  //     type: 'success',
  //   });
  // };
  // const onDone = (): void => {
  //   const resetFinalStep = get(
  //     wizard,
  //     'value.step3Ref.value.ruleFormRef.resetFields',
  //     () => ({})
  //   );
  //   const resetWizardForm = get(wizard, 'value.resetWizard', () => ({}));
  //   resetFinalStep();
  //   resetWizardForm();
  //   isComplete.value = false;
  // };
  // const sendEmails = async () => {
  //   if (!emails.value.length) {
  //     return;
  //   }
  //isSendingEmail.value = true;
  //   await store
  //     .dispatch(Actions.SEND_TENANT_EMAILS, {
  //       emails: emails.value,
  //     })
  //     .then(() => {
  //       ElMessage({
  //         message: 'Email sent to them.',
  //         type: 'success',
  //       });
  //     })
  //     .catch(() => {
  //       ElMessage({
  //         message: "We could't send email at this time.",
  //         type: 'error',
  //       });
  //     })
  //     .finally(() => {
  //       isSendingEmail.value = false;
  //       emails.value = [];
  //     });
  // };
  // const validationSchema = Yup.object({
  //   emails: Yup.array().of(Yup.string().email().required()),
  // });
  // const evaluateEmail = async () => {
  //   const { setValues, validate, errors, resetForm } = useForm({
  //     validationSchema,
  //   });
  //   let isValid = true;
  //   setValues({ emails: emails.value });
  //   await validate()
  //     .then(async () => {
  //       if (!isEmpty(cloneDeep(errors.value))) {
  //         ElMessage({
  //           message: 'You enter invalid email!',
  //           type: 'error',
  //         });
  //       } else {
  //         await sendEmails();
  //       }
  //     })
  //     .finally(() => {
  //       resetForm({ values: { emails: [] } });
  //     });
  //   return isValid;
  // };
  // const _input = (evt) => {
  //   if (evt.target.value) {
  //     emails.value = [...[evt.target.value], ...emails.value];
  //   }
  // };
  // return {
  //   isSendingEmail,
  //   isComplete,
  //   //evaluateEmail,
  //   copyLink,
  //   onDone,
  //   submit,
  //   wizard,
  //   emails,
  //   link,
  //   _input,
  // };
  //},
});
